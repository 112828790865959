<template>
    <app-page fullscreen class="d-flex justify-content-center align-items-center">
        <div>
            <img :src="require('../assets/logo.png')"/>
            <h1 class="mt-3">Welcome to Eye-Infinite Widgets</h1>
            <p>Please Configure from Eye-Intelligence to begin</p>
            <b-btn href="https://eyeintelligence.com">Open Eye-Intelligence</b-btn>
        </div>
    </app-page>
</template>

<script>
export default {
    name: `home`
}
</script>

<style lang="scss" scoped>
</style>
